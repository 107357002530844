import React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Helmet } from 'react-helmet'
import ReactMarkdown from 'react-markdown'

import Header from '../../components/Header/Header'
import Layout from '../../components/Layout/Layout'

const YouthCivicEngagement = ({ data }) => {
const headerImage = getImage(data.markdownRemark.frontmatter.headerImage.photo)
const collegeImage = getImage(data.markdownRemark.frontmatter.collegesPhoto.photo)

  return (
    <Layout title={data.markdownRemark.frontmatter.heading}>
      <Helmet>
        <script type="text/javascript">{`
          document.querySelector('[data-tool="verify"]').classList.add('hidden')
          document.querySelector('[data-tool="register"]').classList.add('hidden')
          document.querySelector('[data-tool="locate"]').classList.add('hidden')
        `}
        </script>
      </Helmet>
      <Header background={headerImage} alt={data.markdownRemark.frontmatter.headerImage.altText}>{data.markdownRemark.frontmatter.heading}</Header>
      <div className="main">
        {data.markdownRemark.frontmatter.blurb ? <ReactMarkdown children={data.markdownRemark.frontmatter.blurb} /> : null}
        <h2>Colleges</h2>
        <div className="flex-row flex-mobile">
          <div style={{ flex: '1' }}>
            <p>We have liaisons for these college campuses:</p>
            <ul>
              {data.markdownRemark.frontmatter.colleges.map(college => college.link ? <li key={college.name}><a href={college.link}>{college.name}</a></li> : <li key={college.name}>{college.name}</li>)}
            </ul>
            <p>We need liaisons from the following campuses:</p>
            <ul>
              {data.markdownRemark.frontmatter.needFellowsColleges.map(college => <li key={college.college}>{college.college}</li>)}
            </ul>
            <ReactMarkdown children={data.markdownRemark.frontmatter.collegeLiaisonInfo} />
          </div>
          {data.markdownRemark.frontmatter.collegesPhoto.photo ? ( 
            <div style={{ flex: '1' }}>
              <GatsbyImage image={collegeImage} alt={data.markdownRemark.frontmatter.collegesPhoto.altText} className="center-image" /> 
            </div>
          ) : null}
        </div>
        <p className="center-text">To start a Mississippi Votes Chapter at your college or university, you can <a href={data.markdownRemark.frontmatter.constitution} target="_blank" rel="noreferrer">download our constitution here</a>.</p>
        <h2>High Schools</h2>
        <ReactMarkdown children={data.markdownRemark.frontmatter.highSchoolLiaisonInfo} />
        <p>Want to be a fellow? <Link to="/opportunities/fellowships">Check out open fellowships.</Link></p>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`query YouthCivicEngagementQuery {
  markdownRemark(frontmatter: {title: {eq: "Youth Civic Engagement"}}) {
    frontmatter {
      heading
      headerImage {
        photo {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        altText
      }
      blurb
      colleges {
        name
        link
      }
      collegesPhoto {
        photo {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              aspectRatio: 0.75
            )
          }
        }
        altText
      }
      needFellowsColleges {
        college
      }
      collegeLiaisonInfo
      constitution
      highSchoolLiaisonInfo
      collegeContact {
        name
        email
      }
      highSchoolContact {
        name
        email
      }
    }
  }
}
`

export default YouthCivicEngagement